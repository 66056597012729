import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <p>{`Inputs come in two different colors. The default input color is `}<inlineCode parentName="p">{`$field-01`}</inlineCode>{` and is used on `}<inlineCode parentName="p">{`$ui-background`}</inlineCode>{` and `}<inlineCode parentName="p">{`$ui-02`}</inlineCode>{` page backgrounds. The `}<inlineCode parentName="p">{`--light`}</inlineCode>{` version input color is `}<inlineCode parentName="p">{`$field-02`}</inlineCode>{` and is used on `}<inlineCode parentName="p">{`$ui-01`}</inlineCode>{` page backgrounds.`}</p>
    <p>{`If a `}<inlineCode parentName="p">{`min date`}</inlineCode>{` is set on the calendar, then any text appearing before that date will be `}<inlineCode parentName="p">{`$disabled-03`}</inlineCode>{`, otherwise all text should be `}<inlineCode parentName="p">{`$text-01`}</inlineCode>{` (unless the number is selected or highlighted).`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$field-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__input`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`.bx--text-input--light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$field-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-04`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:placeholder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`calendar`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`calendar`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`box-shadow`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0 12px 24px 0`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`rgba(0, 0, 0, 0.1)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`day`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`day.selected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-04`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`day.selected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`day.inRange`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`day.endRange`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`day.nextMonthDay`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.today`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.84782608695653%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAAB9ElEQVQ4y42Tv2/TQBTHHRAI4QIr/A2MbAyMSCyMMCHByIKK1LH8EezA1qVLBzZAYmqrgooEUWqXOL8cx3e2z3c+24ldx87j+SIWhJM86at35++7j96zfZr2b9x5pG0e71fb9x+/VPnuvQfaw2dvtp4CXNL2oKVpbZSLwvUN1Lv62eXlodvQDDz4nqi8+wWuHbXd/W+/eqc/f5PDkT87HnrToz5JDy0nOj05o/uvDuB6Xbt3nDUDzRFv1flrm+s2EcO8BBBxDn6YAGUS5LSAOQDYhDuff3g369p2L2w1AjnzlemO+3ok+FmWZRDHsvAoKT2PlkkcF3megYzEuWv3FDAMaDPQ9wNljm1bZyw0F/MZpLGohIhACAEiiqosyyEMw25/MFJASr1mYBAsgYZl61KEpuEWYE1kFUuECQ6MyyqdFSA47/YGQwX0PG9Vh8uRP55QfZ765pMPAG8/pVU1Y+CzEDjnVZqmwBjrjsfj9cC/HU5cqrMgMAORAvXDCkcE9OpRqyRJFHDiOJt3SAnR8X12klgihF0EAZsjcI6gIooiQMi567rrgY7jKHM6nW4NBoPJRVFAWVawWCyUyhL/Iwz8QBT9W2uBlmWpLKW8YhjGc9zv4IFt7OY1IaTWNqV0B/cvOp3O1boWwauvH3ax8U3+H+wPbDuh6LxcX1sAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Example of a date picker",
          "title": "Example of a date picker",
          "src": "/static/f7a2080223dd290cbb4ca0bc93540f13/fb070/date-picker-style-1.png",
          "srcSet": ["/static/f7a2080223dd290cbb4ca0bc93540f13/d6747/date-picker-style-1.png 288w", "/static/f7a2080223dd290cbb4ca0bc93540f13/09548/date-picker-style-1.png 576w", "/static/f7a2080223dd290cbb4ca0bc93540f13/fb070/date-picker-style-1.png 1152w", "/static/f7a2080223dd290cbb4ca0bc93540f13/c3e47/date-picker-style-1.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Date picker example using both $field-02 (top) and $field-01 (bottom)
    </Caption>
    <h3 {...{
      "id": "interactive-states"
    }}>{`Interactive states`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`input:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`input[data-invalid]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--form-requirement`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`input[data-invalid]:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`day:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`day:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-ui`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`input:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`input:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`icon:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-02`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Active:`}</strong>{` Placeholder text should remain when the user clicks into the text input and gets a cursor. Once the user starts typing the hint text is replaced with the user input text.`}</p>
    <p><strong parentName="p">{`Error:`}</strong>{` Error messages appear below the input field and are always present while invalid.`}</p>
    <p><strong parentName="p">{`Disabled:`}</strong>{` Disabled state should have a `}<inlineCode parentName="p">{`.not-allowed`}</inlineCode>{` cursor on hover.`}</p>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`Labels should be set in sentence case, with only the first word in a phrase and any proper nouns capitalized, and no more than three words.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--form-requirement`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <h3 {...{
      "id": "simple-date-pickers"
    }}>{`Simple date pickers`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 / 2.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.14673913043478%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsSAAALEgHS3X78AAACUklEQVQ4y4VSTWgTQRSezUYtwYM3sdRLhdSD7UG8eBAETyIIXjzqoWrVSxBsqQVFEDE20vgT7KGX0gpSehA1LbZpA9qUIII2mjRmk3abnZ2dzW52s83Wg4qsMxtXGneJD2a/2e/NfPPexwNfui6BVlEWoY28Zblyi5rmfSkX7AOZwyG2ePKaPwaAr5gp91YN/Zb5bes0zaeRyFAcKxT2rkI4iA1jYKaqdto5XWc8BT/3XG3LHu8PvADAr66LwzJEORXhMM0vlQWW4iiWDuRleW5NlpcnhfIxyr1Boq9JzKvlLKkyDYK7k+DITmt7q9dDfvK1BdqjkYBDv1QUd9upjj4b33t49d3cZPo9eBpDP366yUzXRVAhF+pvZ5goACwsokdaTZ83DGPBNM2jzrkJQejMYzxb1/W5rKbFzinKLsrHq1W3IG2NG08wCSKolvhe9LX0FHHro9Uy6nbOhddKHR9EeF/DOLywwd84sfLRFoxxnLvKiRajI2/VGS8+ND3FOvtxKDQno6Ct5TyKluUpupha8vbwE2l581mEeQV6/KWMcAUi8a5eqw1IueJtVarsp+eeC0J7hufvaJXKUFZRbiYROkv5uojAJBLdHsJ4ihkjY6EJ0j0Fy8sKwgmYLyZlKB2ybZHlg3lFma+papx4mX7N8xHKWxJmolyhucok2Af+G3sC7D+M7/L0VMOKM6eaK6Sxen4YvCP4i/hlWpaPIiboDDdFsnwbosha2/jB2bj78ZXghb/idE/R2ae6G0O/Y+QBAE+ijQuPRxor9rDx/4f/DeQMRO8tshIWAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure for simple and single date pickers",
          "title": "Structure for simple and single date pickers",
          "src": "/static/79faab95a0d161f45a3ed291f6cff2c2/fb070/date-picker-style-4.png",
          "srcSet": ["/static/79faab95a0d161f45a3ed291f6cff2c2/d6747/date-picker-style-4.png 288w", "/static/79faab95a0d161f45a3ed291f6cff2c2/09548/date-picker-style-4.png 576w", "/static/79faab95a0d161f45a3ed291f6cff2c2/fb070/date-picker-style-4.png 1152w", "/static/79faab95a0d161f45a3ed291f6cff2c2/c3e47/date-picker-style-4.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure and spacing for simple and single date pickers | px / rem
    </Caption>
    <h3 {...{
      "id": "single--range-date-pickers"
    }}>{`Single & range date pickers`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 / 2.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`288 / 18`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-right, padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__calendar`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`336 / 21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__calendar`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`288`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__calendar`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-top, padding-right, padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 / 0.25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__calendar`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--date-picker__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-right, padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.today`}</inlineCode>{` dot`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height & width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 /0.25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`day`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height & width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 / 2.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "73.36956521739131%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAACp0lEQVQ4y52TXUgUURTHZ3ZlFykfgkALBF/KeggCCwmjDx8i6K2HoJceAvXFEjTBQirppZdQkIoIorYeKugDLVBMyyXFj9YdFVklNN2mvfNxZ+58rKvjrHO6d8yH1JXowOGce+fcH/9zz1yOozZeWsVNHG3gnEOnuYf5jZwJEEgDBBOimRcz3CDQnDkmep4ObpDoOjvGkbTNbTIG+x/TMc79kUHHyuqCUHqOb9txLdQzNFcVnxZbZuZJfTw60zyVSN6SpNQNkxj1qfh0MxGlvT40ificwPHDtTuTJRfCncV1uxI/9MklDwBbWRBTNmA1DSuuA67rQsayQUbSCXZOkaSAsLHDjS2PldeEVAUPZpYcMEx7WUwhd2521iWErNi27Rqm6SKEKlitLMuBLa9tYn81N3s74ssfOVIb1lQ85DjLYBgka1mmZ5rrboBBCMiK7ANVDQe2nAHbpFP08+Hy2hAFDjsOVWgYWarM03XdI4bpadgEIimw0DNyhpbyaOr7ZoXri6nKpjyh+FLBRNmVAlVWhhiQgrKapoGGMWBN8xRFA4xkEIXpU+wMmv+ZGzhT3cbHuLNBobwuX1WU4eU/QOrAoL5KorMIsob9lhWs5m5ZW13y88mTjSGmcDGTYSCXgVRV9Wi+St3DGHsSQsdzDmV9IX4Y8GOi8npYkZVR1rJG1dBhsLv03bIsIDQiSfoLuKUl30XXFFY0hOUU6lcVdZXenUVBNnWLPre0oZM0jYvoV+qYD0RSbmC05q4fuwvP89HWyO6BR6/2fHn8sjDa1F7y9eq9fX3tz4v62iNFva1Pi3pv3g+x2k8tD7Z/n28PXvznt/wt0rF9QezOEz8OHrjMxd9080JXPy+87uLj9P+MURc6ennh42de6OzjR5+9X4O+6NzE+Q1Yp/aJ8toXGgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure for date picker",
          "title": "Structure for date picker",
          "src": "/static/82b66aa85511ff38fc2443adb396a635/fb070/date-picker-style-2.png",
          "srcSet": ["/static/82b66aa85511ff38fc2443adb396a635/d6747/date-picker-style-2.png 288w", "/static/82b66aa85511ff38fc2443adb396a635/09548/date-picker-style-2.png 576w", "/static/82b66aa85511ff38fc2443adb396a635/fb070/date-picker-style-2.png 1152w", "/static/82b66aa85511ff38fc2443adb396a635/c3e47/date-picker-style-2.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Structure for date picker | px / rem</Caption>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "73.36956521739131%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAACYklEQVQ4y5WTz2sTQRTHJ4kiQU/iSfAkXowgXm1PAf8A8eClVREEUaiHilrFKoiKeFM8FIqKPxA8Fa1WK0lBbBqw2oZ20w10N5us2eyP2Z3dJrHJJtlxZjcJwWSlPvjy3sCbD983PwBoBcYYRK+ca9eBZQ6FIMJBWhMFZU0N0dxad/b0xs0xL58/BcDtu+DYvWtgK9Ef9jeUxggLJlLZ45nsxlA8I59+/DNzYaUIh3G5fDItF8+8zrAXHzGre2nrkzQT8IXtfnB/e3hsdBcYvxOeFZTFYrmJV2QTx/IKXpM1DC0TC6aJk4qCp8X8AN0zI4pBX+DA5ERg59XLITB0IsSpWmKzVseGVbJFqdhc5/mmjlDDKpWaGkLOLLfuAudzuaDvyN1nktfURL1Ww5ZpNkwTOYZhOJZlORvEJQHjuSw/SPuS+by/wzjPe+ttAOQ0baFu29gkQIQ8oIFMxzBLLjDGe8CFvsBWfC/88or9+4CgKC6QgBpENGOdQHXDwpqhdwFzWwBGDvQAdV0nte4gUqsQ4ngb+K8zXJQKXn12KMDLcuJ3pYI1COsE5qiq6kAIm0SOrGlOjOfcS0nkBH+HX4VspxZkOWlXq9ggMIucmUmcUZUsC0PiNsZx3qVQh91vuO8tj1/fsSpJ34oEJEJYlRCyC4ZBVZNIFnXd/sTzR2nrnCD4j/yjDbx1Izzy5fPhNwxz6HlqOfJ0aSnyjIjml6nUwYeJ+SPRVy/20Nbh9+9Af4c0ooPgf2KzbcBvZPcJjl4CYOqtO/5Mhg1Os2uBDyzr6iPRFMME04ra+b9spdKD+gMkoN7aMM4FMgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Spacing for date picker",
          "title": "Spacing for date picker",
          "src": "/static/6d4e86b22df582877806c8079f093259/fb070/date-picker-style-3.png",
          "srcSet": ["/static/6d4e86b22df582877806c8079f093259/d6747/date-picker-style-3.png 288w", "/static/6d4e86b22df582877806c8079f093259/09548/date-picker-style-3.png 576w", "/static/6d4e86b22df582877806c8079f093259/fb070/date-picker-style-3.png 1152w", "/static/6d4e86b22df582877806c8079f093259/c3e47/date-picker-style-3.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Spacing for date picker | px / rem</Caption>
    <h3 {...{
      "id": "time-picker"
    }}>{`Time picker`}</h3>
    <p>{`There are two types of time pickers; a 12-hour and 24-hour time picker. The 12-hour Time Picker is accompanied by a time period (am/pm) input, while the 24-hour clock is not. Refer to `}<a parentName="p" {...{
        "href": "/components/select"
      }}>{`select`}</a>{`, for inline select styling.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--time-picker__input-field`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 / 2.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--time-picker__input-field`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-right, padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-right, padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "53.66847826086957%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABwElEQVQoz52QW0sbQRTHZ+KLVgXBR6FF1ESs1NI8+xn0Rd/ERryBolCffOx30JcW+tKSXoyIPiipFS8IKt42u65GzRBMspnZ2dmbsXhnza4XlK42eODwP3PmPz/OGRDxdQA7bOXafzg132qByEIcijsiTMTi0FA1eKjq0DQMmMlkoJHE0GSa4zVlBh7ELfCgvB9EYD1Yrw+CXCNDNfcLwdv14PxlwMoL/9p4vbS6VLO/tVNxdnlRZsbTr46o9vLSssrUmQ2fzqNC26utbLtPKPg6geDve8FVBQq233wopkk8p1B6yqiSxklJNYgiZ2tKZaqoSXysp+VGB5giHlfglrcT8G974GZ1N4xVNsO9T2Pe6OcJ/+63qVpxKOTf//67NjoyXSMOh97tBsN1aJkrst/FZldcVs5O95zQvk7+27xb2XsNnbasnIE/T/4+bRhFyNGPCBWsI9TGIdT7J5EI8IS8n2esRWIsYOcqY23jilLiDMDY48AQIdDWQYxLeYIXorIshFMpbk2SxDAh3AEhQpwQfpGQvaAkVdrecYzh48T8G21q8OSysvW/77lvyNbwLk/PHT3Sdc+Jeei56btCrwDetgip1nJUJwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure for a time picker",
          "title": "Structure for a time picker",
          "src": "/static/dad61c3aa7338efa26d9c0eccda08800/fb070/time-picker-style-1.png",
          "srcSet": ["/static/dad61c3aa7338efa26d9c0eccda08800/d6747/time-picker-style-1.png 288w", "/static/dad61c3aa7338efa26d9c0eccda08800/09548/time-picker-style-1.png 576w", "/static/dad61c3aa7338efa26d9c0eccda08800/fb070/time-picker-style-1.png 1152w", "/static/dad61c3aa7338efa26d9c0eccda08800/c3e47/time-picker-style-1.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Structure and spacing for a time picker | px / rem</Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      